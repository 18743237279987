import React, { useEffect, useState } from 'react';
import './PhoneNumberPopup.css'; // Import the CSS file

const PhoneNumberPopup = ({ initialPhoneNumber, closePopup }) => {

  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || '');
  const [view, setView] = useState(1);
  const [error, setError] = useState('');
  useEffect(() => {
    setView(initialPhoneNumber.length > 0 ? 2 : 1);
    setPhoneNumber(initialPhoneNumber);
  },[initialPhoneNumber])
  

  const validatePhoneNumber = (number) => {
    const regex = /^07[0-24-8]\d{7}$/;
    return regex.test(number);
  };

  const handleConfirm = () => {
    if (validatePhoneNumber(phoneNumber)) {
       let phnNumb = phoneNumber == initialPhoneNumber ? '' : phoneNumber;
       closePopup(phnNumb); // Close the popup
    } else {
      setError('Invalid Phone Number. Enter Valid Phone Number');
    }
  };

  const handleUpdate = () => {
    setView(3);
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        {view === 1 && (
          <>
            <h3>Enter Your Phone Number</h3>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="07XXXXXXXX"
              className="input-field"
            />
            {error && <p className="error">{error}</p>}
            <button onClick={handleConfirm} className="btn">Confirm</button>
          </>
        )}
        {view === 2 && (
          <>
            <h3>Confirm Your Phone Number</h3>
            <p>{phoneNumber}</p>
            <button onClick={handleConfirm} className="btn">Confirm</button>
            <button onClick={handleUpdate} className="btn">Update</button>
          </>
        )}
        {view === 3 && (
          <>
            <h3>Enter Your New Phone Number</h3>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="07XXXXXXXX"
              className="input-field"
            />
            {error && <p className="error">{error}</p>}
            <button onClick={handleConfirm} className="btn">Confirm</button>
          </>
        )}
      </div>
    </div>
  );
};

export default PhoneNumberPopup;
