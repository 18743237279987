import React from 'react';
import './CIMPopup.css'; // Import the CSS file
import websiteIcon from './cim.png';

const CIMPopup = ({ closePopup }) => {
  const handleOpenWebsite = () => {
    window.open('https://onelink.to/pmbfqp', '_blank');
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h3>Download the LB CIM App</h3>
        <button onClick={closePopup} className="close-btn">X</button>
        <button onClick={handleOpenWebsite} className="website-btn">
          <img src={websiteIcon} alt="Open Website" className="website-img" />
        </button>
      </div>
    </div>
  );
};

export default CIMPopup;
